import React from "react";
import ReactDOM from "react-dom";

if (process.env.NODE_ENV === "production") {
  function importBuildTarget() {
    if (process.env.REACT_APP_BUILD_TARGET === "unprotected") {
      return import("./views/containers/UnprotectedApp");
    } else if (process.env.REACT_APP_BUILD_TARGET === "protected") {
      return import("./views/containers/ProtectedApp");
    } else {
      return Promise.reject(
        new Error("No such build target: " + process.env.REACT_APP_BUILD_TARGET)
      );
    }
  }

  importBuildTarget().then(
    ({ default: Application }: { default: React.ElementType }) => {
      ReactDOM.hydrate(
        <React.StrictMode>
          <Application {...(window as any).__INITIAL__DATA__} />
        </React.StrictMode>,
        document.getElementById("root")
      );
    }
  );
}

if (process.env.NODE_ENV === "development") {
  import("./App").then((m) => {
    const App = m.default;
    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById("root")
    );
  });
}
